import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import P from "../components/P";

function ContactSubmittedPage() {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <header>
        <h1>Contact Us</h1>
      </header>
      <P>Your message has been sent! We will be in touch with you shortly.</P>
    </Layout>
  );
}

export default ContactSubmittedPage;
